<template lang="html">
    <div>
        <h1>Příslušenství, mušle, kování</h1>

        <div class="img-preview">
            <img src="@/assets/images/doplnkovyprodej_2020.jpg" alt="Příslušenství, mušle, kování"/>
        </div>

        <p class="mt-xl">
            Vybrali jste si z naší bohaté nabídky ty pravé a jediné dveře? Pak ještě zbývá zvolit k nim vhodnou kliku a kování. 
            V současné době je na trhu široké množství klik v různých tvarech, typech, či kombinacích s nejrůznějším kováním. 
            V našem katalogu si můžete vybrat hned z několika variant kování rozetového i štítkového.
        </p>
        <p>
            Klika i kování by ke dveřím měly opticky ladit. Vybírat můžete i z různých materiálů – nerezové oceli, hliníku, 
            ale i bronzu, niklu nebo chromu. Vybírejte však nejen podle vzhledu. Nezapomínejte, že například k bezpečnostním 
            dveřím musíte zvolit i správné bezpečnostní kování. Kliku vybírejte také podle tvaru tak, aby vám příjemně padla do ruky.
        </p>
        <p>
            Pokud si dveřní kování vyberete u nás, budete mít jistotu, že vaše dveře budou nejen prvotřídní a moderní, 
            ale že kvalitní doplňky k nim budou také správně pasovat a dlouho vydrží.
        </p>
        <p>
            Přejeme vám, ať máte kliku celý život!
        </p>
    </div>
</template>