<template lang="html">
    <div class="d-f fd-r fw-w list-menu mt-xl" 
        :class="{
            'list-menu__interior': storeName == 'interiorDoors',
            'list-menu__posuvne': storeName == 'posuvne',
            'list-menu__hrany': storeName == 'hrany'}
        ">
        <a v-for="item in items" :key="item.id" class="d-f fd-c items-list__item c-p" 
            @click.prevent="setActive(item.id)">
                <img :src="require('@/assets/images/' + storeName + '_' + item.id + '.jpg')" 
                    :alt="item.name" class="items-list__img"/>
                <span class="mt-md">{{item.name}}</span>
        </a>
    </div>
</template>
<script>
import { useRouter } from 'vue-router'
import {computed, ref} from 'vue'
import {useStore} from "vuex"

export default {
    props: {
        storeName: String
    },
    setup(props) {
        const router = useRouter()
        const store = useStore();

        let items = computed( function () {
            if(props.storeName == 'interZarub') {
                return store.state.interiorZarub
            }  else if (props.storeName == 'interiorDoors') {
                return store.state.interiorDoors
            } else if (props.storeName == 'posuvne') {
                return store.state.posuvne
            } else if (props.storeName == 'hrany') {
                return store.state.hrany
            }
            return null
        });

        function setActive(name) {
            router.push({query: {type: props.storeName, product: name}})
        }

        return {
            items,
            router,
            setActive,
        }
    }
}
</script>
<style lang="scss">
@import "@/resources/styles/Base/Mixins/_responsive.scss";
.list-menu {
     gap: var(--gap-md);

     > * {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 10rem;
        flex: 0 0 10rem;
        text-align: center;
        
        img {
            min-height: 10rem;
            max-width: 100%;
        }
     }
     

     &__hrany {
         img {
             min-height: 10rem;
         }
     }

     &__interior {
         img {
             max-width: 8rem;
             margin-top: var(--gap-md);
             order: 2;
         }
         span {
             text-transform : uppercase
         }
     }

     &__posuvne {
        a {
            overflow: hidden;
        }
        img {
            height: 8rem;
            max-width: unset;
        }
    }

     @include res-max-mobile {
        justify-content: space-between;
        gap: var(--gap-lg);

        > * {
          max-width: 47%;
          flex: 0 0 47%
        }
     }
   }
</style>