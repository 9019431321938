<template lang="html">
    <div>
        <h1>Dekory interiérových dveří</h1>
        <p>
            Při výběru interiérových dveří hraje důležitou roli nejen kvalitní materiál a řemeslné provedení, ale i vzhled dveří. 
            Ten hodně závisí na vhodně zvoleném dekoru.  Interiérové dveře Solodoor jsou vyráběny v široké škále dekorů a barev, 
            které vám umožní dveře dokonale sladit s vaším interiérem.        
        </p>
        
        <h2>SOLO MATRIX</h2>
        <ul class="list">
            <li>Autentický dřevěný vzhled</li>
            <li>Vysoká odolnost proti mechanickému poškození</li>
            <li>Mimořádně odolný vůči vlhkosti a slunečnímu záření</li>
            <li>Ideální moderní prvek pro každý interiér</li>
            <li>Dokonalá rovnováha mezi estetikou a funkčností</li>
            <li>Jednoduché ošetření běžnými čistícími prostředky</li>
        </ul>
        <div class="list-items d-f fw-w mt-lg">
            <div v-for="item in listSoloMetrix" :key="item.name" class="c-p" 
                @click="selectedImg = 'povrch/' + item.preview  + '-big.jpg'">
                <div class="list-items__preview">
                    <img :src="require('@/assets/images/povrch/' + item.preview + '.jpg')" :alt="item.name"/>
                </div>
                <span>{{item.name}}</span>
            </div>
        </div>

        <hr>

        <h2>CPL SOLO 3D</h2>  
        <p>
            Dekor SOLO 3D představuje špičkové řešení, které spojuje autentický přírodní vzhled s vysokou odolností. 
            Tento povrch dokonale imituje dřevo, takže jej na pohled nerozeznáte od dřevěných dveří.  
            Přírodní vzhled dřeva vytváří nejen barevné provedení, ale i unikátní 3D povrch, který věrně napodobuje povrchovou strukturu dřeva včetně suků, rýh a prohlubní.
        </p>
        <p>
            Krásný design dveří nijak nezmenšuje praktickou funkci tohoto povrchu: dekor SOLO 3D je maximálně odolný a 
            připravený odolávat všem nástrahám každodenního užívání, jako je poškrábání nebo vlhkost. 
            Dveře v této povrchové úpravě jsou také barevně stálé a i po letech udrží svůj krásný vzhled a jasnost barev. 
            Další výhodou je i jejich nižší cena oproti dýhovaným dveřím a dveřím z přírodního masivu. 
            Pokud máte rádi přírodní materiály a přitom požadujete vysokou odolnost a snadnou údržbu, jsou pro vás dveře s povrchovou úpravou SOLO 3D to pravé.
        </p>
        <ul class="list">
            <li>Dokonalá imitace struktury dřeva na pohled i dotek</li>
            <li>Velmi odolný proti mechanickému poškození</li>
            <li>Mimořádně odolný vůči vlhkosti a slunečnímu záření</li>
            <li>Jednoduché ošetření běžnými čistícími prostředky</li>
        </ul>
        <div class="list-items d-f fw-w mt-lg">
            <div v-for="item in listCPLSolo3D" :key="item.name" class="c-p"
                @click="selectedImg = 'povrch/' + item.preview  + '-big.jpg'">
                <div class="list-items__preview">
                    <img :src="require('@/assets/images/povrch/' + item.preview + '.jpg')" :alt="item.name"/>
                </div>
                <span>{{item.name}}</span>
            </div>
        </div>

        <hr>
        <h2>CPL</h2>
        <p>
            Interiérové dveře se vyrábějí s celou řadou povrchových úprav. Jednou z nich je speciální laminátový povrch dekor CPL. 
            Je dobrou alternativou dýhy, kterou svým vzhledem připomíná. Na rozdíl od ní však nabízí vyšší odolnost materiálu.
        </p>
        <p>
            Laminát CPL je druh kompozitního materiálu, který tvoří vrstva papíru a několik vrstev impregnované pryskyřice.  
            Jeho hlavní výhodou je cenová dostupnost, která jde ruku v ruce s vysokou kvalitou a odolností. 
             Dekor CPL je mimořádně odolný proti veškerým vnějším vlivům, jako je poškrábání, vlhkost nebo sluneční záření. 
             Díky tomu jsou dveře s touto povrchovou úpravou vhodné do jakýchkoliv interiérů. Svou funkci splní v domácnosti, 
             stejně jako v kanceláři nebo ve výrobních prostorách.  Dekor CPL je ideální pro rodiny s dětmi a domácími mazlíčky. 
             Oproti dýze se vyznačuje také barevnou stálostí, takže jeho vzhled zůstane i po letech stejný, bez jakýchkoliv barevných změn. 
             Dekor CPL nabízíme v široké škále dezénů, díky kterým dveře snadno doladíte k vašemu interiéru.
        </p>
        <ul class="list">
            <li>Velmi odolný proti mechanickému poškození</li>
            <li>Mimořádně odolný vůči vlhkosti a slunečnímu záření</li>
            <li>Jednoduché ošetření běžnými čistícími prostředky</li>
        </ul>
        <div class="list-items d-f fw-w mt-lg">
            <div v-for="item in listCPL" :key="item.name" class="c-p"
                @click="selectedImg = 'povrch/' + item.preview  + '-big.jpg'">
                <div class="list-items__preview">
                    <img :src="require('@/assets/images/povrch/' + item.preview + '.jpg')" :alt="item.name"/>
                </div>
                <span>{{item.name}}</span>
            </div>
        </div>

        <hr>
        <h2>SOLO STRUKTUR</h2>
        <ul class="list">
            <li>Věrná imitace přírodních materiálů (dřevo, beton)</li>
            <li>Cenově výhodné řešení</li>
            <li>Jednoduché ošetření běžnými čistícími prostředky</li>
        </ul>
        <div class="list-items d-f fw-w mt-lg">
            <div v-for="item in listSolorStruktur" :key="item.name" class="c-p"
                @click="selectedImg = 'povrch/' + item.preview  + '-big.jpg'">
                <div class="list-items__preview">
                    <img :src="require('@/assets/images/povrch/' + item.preview + '.jpg')" :alt="item.name"/>
                </div>
                <span>{{item.name}}</span>
            </div>
        </div>

        
        <hr>
        <h2>FOLIE</h2>
        <ul class="list">
            <li>Ekonomická varianta s nízkými pořizovacími náklady</li>
            <li>Základní odolnost proti mechanickému poškození</li>
        </ul>
        <div class="list-items d-f fw-w mt-lg">
            <div v-for="item in listFolie" :key="item.name" class="c-p"
                @click="selectedImg = 'povrch/' + item.preview  + '-big.jpg'">
                <div class="list-items__preview">
                    <img :src="require('@/assets/images/povrch/' + item.preview + '.jpg')" :alt="item.name"/>
                </div>
                <span>{{item.name}}</span>
            </div>
        </div>

        <hr>
        <h2>POLAR</h2>
        <ul class="list">
            <li>Speciální bílý lak</li>
            <li>Použití ekologického, vodou ředitelného laku, který nepouští výpary do interiéru</li>
        </ul>
        <div class="list-items d-f fw-w mt-lg">
            <div v-for="item in listPolar" :key="item.name" class="c-p"
                @click="selectedImg = 'povrch/' + item.preview  + '-big.jpg'">
                <div class="list-items__preview">
                    <img :src="require('@/assets/images/povrch/' + item.preview + '.jpg')" :alt="item.name"/>
                </div>
                <span>{{item.name}}</span>
            </div>
        </div>

        <hr>
        <h2>LAMINÁT 0,8 mm</h2>
        <ul class="list">
            <li>Velmi odolný proti mechanickému poškození</li>
            <li>Mimořádně odolný vůči vlhkosti a slunečnímu záření</li>
            <li>Jednoduché ošetření běžnými čistícími prostředky</li>
        </ul>
        <div class="list-items d-f fw-w mt-lg">
            <div v-for="item in listLaminat" :key="item.name" class="c-p"
                @click="selectedImg = 'povrch/' + item.preview  + '-big.jpg'">
                <div class="list-items__preview">
                    <img :src="require('@/assets/images/povrch/' + item.preview + '.jpg')" :alt="item.name"/>
                </div>
                <span>{{item.name}}</span>
            </div>
        </div>

        
        <hr>
        <h2>RAINBOW</h2>
        <ul class="list">
            <li>Speciální lak</li>
            <li>Použití ekologického, vodou ředitelného laku, který nepouští výpary do interiéru</li>
            <li>Odpovídá nejnovějším barevným trendům</li>
        </ul>
        <div class="list-items d-f fw-w mt-lg">
            <div v-for="item in listRainbow" :key="item.name" class="c-p"
                @click="selectedImg = 'povrch/' + item.preview  + '-big.jpg'">
                <div class="list-items__preview">
                    <img :src="require('@/assets/images/povrch/' + item.preview + '.jpg')" :alt="item.name"/>
                </div>
                <span>{{item.name}}</span>
            </div>
        </div>


        <hr>
        <h2>RAL</h2>
        <ul class="list">
            <li>Lakovaná úprava, možnost vybrat odstín barvy dveří</li>
            <li>Použití ekologického, vodou ředitelného laku, který nepouští výpary do interiéru</li>
        </ul>
        <div class="list-items d-f fw-w mt-lg">
            <div v-for="item in listRal" :key="item.name" class="c-p"
                @click="selectedImg = 'povrch/' + item.preview  + '-big.jpg'">
                <div class="list-items__preview">
                    <img :src="require('@/assets/images/povrch/' + item.preview + '.jpg')" :alt="item.name"/>
                </div>
                <span>{{item.name}}</span>
            </div>
        </div>

        <hr>
        <p><b>Náš tip:</b> Zde zobrazené barevné odstíny jednotlivých dekorů nemusí odpovídat skutečnosti a závisí na kalibraci barev Vašeho monitoru. 
        Doporučujeme Vám proto, abyste si výběr dekoru povrchu vždy raději ověřili podle vzorníku dekorů. Vzhledem k použití různých materiálů a postupu výroby, 
        může být rozdíl v dekoru dveří, zárubní, rámečků, garnýže, sloupků.</p>
        <p>Pokud preferujete přírodní vzhled dveří, můžete si vybrat dekor v barvě dřeva. Speciální laminátové povrchy CPL 0,2 se 
        vyznačují vysokou odolností a přitom malou náročností na údržbu. Esteticky dokonalé řešení pro náročné představuje povrch 
        CPL SOLO 3D, který připomíná dřevo nejen vizuálně, ale i na dotek, a to díky speciálnímu strukturovanému 3D povrchu. 
        Naprostou špičkou, kterou najdete exkluzivně v SOLODOOR, je pak povrch SOLO MATRIX, který jsme pro Vás přivezli přímo z Itálie. 
        Je charakteristický mimořádnou odolností proti vnějším vlivům (vlhkost, sluneční záření, mechanická zátěž), 
        jeho vzhled je přitom perfektní a údržba velmi snadná. Pouze v SOLODOOR najdete speciální povrch SOLO STRUKTUR, 
        který věrně imituje přírodní materiály jako například dřevo nebo beton. 
        Potěší Vás určitě svou příjemně nízkou pořizovací cenou, jednoduchou údržbou i velký výběrem dekorů.</p>
        <p>Ekonomickým variantou povrchu s nízkými pořizovacími náklady a základní odolností proti mechanickému poškození je FÓLIE.</p>
        <p>Opomenout nesmíme ani lakované dveře, které se těší stále větší oblibě. 
            Dveře POLAR se svým speciálním bílým lakem jsou nestárnoucí klasikou, kterou sladíte s jakýmkoliv interiérem. Novinkou v našem sortimentu je speciální lak Rainbow s trendy odstínem Heaven.</p>
        <p>V případě, že jste si nevybrali a přesto chcete lakované dveře, jsme schopni Vám dodat dveře nalakované v jakémkoliv odstínu RAL.</p>
        
        <Modal :imgPath="selectedImg" v-on:close-modal="selectedImg = ''"/>
    </div>
</template>
<script>
import Modal from './components/imgDetail.vue'
export default {
    components: {
        Modal
    },
    data() {
        return {
            selectedImg: ''
        }
    },
    setup() {
        const listSoloMetrix = [
            { name: 'mondena příčný', preview: 'smMP', image: '' },
            { name: 'rimini příčný', preview: 'smRP', image: '' },
            { name: 'monza', preview: 'smM', image: '' },
            { name: 'trento', preview: 'smT', image: '' },
            /* { name: 'florence', preview: 'smF', image: '' }, */
            { name: 'mondena', preview: 'smMo', image: '' },
            { name: 'rimini', preview: 'smR', image: '' },
            /* { name: 'florence příčný', preview: 'smFP', image: '' }, */
            { name: 'lazio', preview: 'smL', image: '' },
            { name: 'bergamo', preview: 'smB', image: '' },
            { name: 'mystery', preview: 'smMy', image: '' },
            { name: 'monza příčný', preview: 'smMoP', image: '' },
            { name: 'trento příčný', preview: 'smTP', image: '' }
        ]

        const listCPLSolo3D = [
            { name: 'cremeline I', preview: 'cplSoloC', image: '' },
            { name: 'dub přírodní', preview: 'cplSoloDP', image: '' },
            { name: 'dub bělený', preview: 'cplSoloDB', image: '' },
            { name: 'pinie', preview: 'cplSoloP', image: '' },
            { name: 'tabacco', preview: 'cplSoloT', image: '' },
            { name: 'bardolino', preview: 'cplSoloB', image: '' }
        ]

        const listCPL = [
            { name: 'olše  ', preview: 'cplOl', image: '' },
            { name: 'ořech', preview: 'cplOr', image: '' },
            { name: 'šedá  ', preview: 'cplS', image: '' },
            { name: 'kaštan horský', preview: 'cplKH', image: '' },
            { name: 'dub royal ', preview: 'cplDR', image: '' },
            { name: 'antracit  ', preview: 'cplA', image: '' },
            { name: 'bílá premium  ', preview: 'cplBP', image: '' }
        ]

        const listSolorStruktur = [
            { name: 'dub alpský', preview: 'ssDA', image: '' },
            { name: 'dub archico', preview: 'ssDar', image: '' },
            { name: 'dub sonoma', preview: 'ssDS', image: '' },
            { name: 'dub canyon', preview: 'ssDC', image: '' },
            { name: 'andora white', preview: 'ssAW', image: '' },
            { name: 'stockholm', preview: 'ssS', image: '' },
            { name: 'beton', preview: 'ssB', image: '' },
            { name: 'dub western', preview: 'ssDW', image: '' }
        ]

        const listFolie = [
            { name: 'bílá  ', preview: 'fB', image: '' },
            { name: 'buk  ', preview: 'fBu', image: '' },
            { name: 'olše  ', preview: 'fO', image: '' },
            { name: 'ořech  ', preview: 'fOr', image: '' },
            { name: 'wenge  ', preview: 'fW', image: '' },
            { name: 'earl grey  ', preview: 'fEG', image: '' }
        ]

        const listPolar = [
            { name: 'bíla Polar', preview: 'pBP', image: '' },
        ]

        
        const listLaminat = [
            { name: 'Šedá', preview: 'lS', image: '' },
            { name: 'Krémově bílá', preview: 'lKB', image: '' }
        ]

        const listRainbow = [
            { name: 'Heaven', preview: 'rH', image: '' },
        ]

        const listRal = [
            { name: '', preview: 'ral1', image: '' },
            { name: '', preview: 'ral2', image: '' },
            { name: '', preview: 'ral3', image: '' },
        ]

        return {
            listSoloMetrix,
            listCPLSolo3D,
            listCPL,
            listSolorStruktur,
            listFolie,
            listPolar,
            listLaminat,
            listRainbow,
            listRal
        }
    }
}
</script>
<style lang="scss">
    hr {
        margin: var(--gap-xl) 0;
    }
</style>