<template lang="html">
    <div>
        <h1>Interiérové dveře se světlíkem</h1>

        <div class="img-preview">
            <img src="@/assets/images/svetlik.jpg" alt="svetlik">
        </div>
        <p>
            Světlo je jeden z prvků, na které se moderní bydlení cíleně zaměřuje. Prosvětlení bytu dokážou napomoci také <b>dveřní světlíky</b>, 
            které propustí paprsky do prostoru bez oken. Můžete si tak jimi oddělit například vstupní chodbu od obývacího pokoje, 
            a nebudete muset po každém příchodu automaticky sahat po vypínači a svítit si na ukládání bot do botníku.
        </p>
        <h2>Co je dveřní světlík?</h2>
        <p>
            Dveřní světlíky jsou skleněné plochy zasazené do speciálních zárubní, jež lze umístit po stranách dveří (i třeba jen po jedné straně) či nade dveře. 
            Do světlíků i do dveří dáváme standardní sklo, po domluvě s námi však můžete zvolit bezpečnostní sklo, jež je sice průsvitné, 
            ale neprůhledné – jen tak dojde k optickému uzavření místnosti a nebudete mít chodbičku na pohled součástí obývacího pokoje. 
            Světlíky lze kombinovat s otočnými dveřmi.
        </p>

        <p>Zárubně vám vyrobíme na míru a světlíky i s dveřmi rádi nainstalujeme. Vy si jen vyberte, které se vám do bytu hodí.</p>

        <div class="img-preview mt-xxl">
            <img src="@/assets/images/svetlik_tabulka.jpg" alt="Tabulka">
        </div>
    </div>
</template>