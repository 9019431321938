<template lang="html">
    <div>
        <h1>{{title}}</h1>

        <div class="img-preview">
            <img :srcset="previewUrl" :alt="title"/>
        </div>

        <div class="d-f fd-r fw-w jc-c mb-xl">
             <a class="box box--btn ma-md" :href="configUrl" target="_blank">
                <div class="box__img">
                    <SvgConfig/>
                </div>
                <span class="mt-lg">Konfigurátor dveří</span>
            </a>
            <a class="box box--btn ma-md" href="https://www.solodoor.cz/cs/vizualizace/" target="_blank">
                <div class="box__img">
                    <SvgVisual/>
                </div>
                <span class="mt-lg">Vizualizace dveří</span>
            </a>
        </div>

        <div v-html="description"></div>

        <div v-if="list" class="product-detail mt-xl">
            <h2>Kompletní modelová řada <span class="name">{{name}}</span>:</h2>
            <div class="product-detail__configurator">
                <a v-for="type in list" :key="type.name" :href="type.configPath" target="_blank">
                    <p class="name">{{type.name}}</p>
                    <img class="img" :src="type.imgPath" />
                </a>
            </div>
            <p>Na konfigurátoru dveří si schválně vyzkoušejte, v jakém dekoru a provedení by se vám dveře RAINBOW hodily nejvíce. Nejste si jistí? Podívejte se zde, jak by ten který model dveří vašemu domovu slušel.</p>
        </div> 
    </div>
</template>
<script>
import SvgConfig from '@/assets/icons/config.svg'
import SvgVisual from '@/assets/icons/visual.svg'
export default {
    components: {
        SvgConfig,
        SvgVisual
    },
    props: {
        id: {
            type: String,
            default: ''
        },
        title: {
            type: String,
            default: ''
        },
        
        name: {
            type: String,
            default: ''
        },
        description: {
            type: String,
            default: ''
        },
        list: {
            type: Array,
            default: null
        }
    },
    computed: {
    },
    setup(props) {
        const configUrl = "https://www.sestavsidvere.cz/cs/" + props.name
        const previewUrl = "/images/product/preview/" + props.id + '_1200.jpg 800w, /images/product/preview/' + props.id + '_600.jpg 600w'
        return {
            configUrl,
            previewUrl
        }
    }
}
</script>
<style lang="scss">
    @import "@/resources/styles/Base/Mixins/_responsive.scss";
    .product-detail {
        &__configurator {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: var(--gap-md);

            > * {
                display: inline-flex;
                flex-direction: column;
                align-items: center;
                flex: 0 0 12%;
            }
            
            @include res-max-mobile {
                width: calc(100vw - 2 * var(--gap-lg));
                overflow: auto;
                flex-wrap: nowrap;

                > * {
                    flex: 0 0 20%;
                    display: grid;
                    grid-template-rows: 1fr auto;
                }
            }
        }

        .img {
            max-width: 100%;
        }
    }

    .list li {
        list-style: square;
        margin-left: var(--gap-lg);
    }

</style>