<template lang="html">
    <div>
        <h1>Možnosti úpravy dveří</h1>
        <p>Nabízíme vám celou řadu speciálních úprav, které vychází vstříc vašim požadavkům na vysokou funkčnost a jedinečný design.</p>
        <p>Exkluzivní designové řešení představují <b>bezfalcové dveře</b>, které splývají se stěnou a nijak nevyčnívají z prostoru. 
            Vašemu interiéru dodají elegantní čisté linie a moderní vzhled. 
            Hledáte originální designový prvek, díky kterým budou vaše dveře elegantní, ale i bezpečné? Zvolte <b>oblou hranu dveří</b>.</p>
        <p>Moderní a praktické zároveň jsou <b>posuvné dveře</b>. Díky této variantě dveří ušetříte místo, takže je zvláště vhodná do menších interiérů. 
            Posuvné dveře se dají buď zasunout do stavebního pouzdra, nebo mohou být <b>posuvné do garnýže</b>, k jejichž instalaci nepotřebujete žádné stavební úpravy.</p>
        <p>Potřebuje váš pokoj více světla? Prosvětlete jej pomocí <b>světlíků</b>. Světlíky dodají místnosti za dveřmi více denního světla a opticky ji zvětší.</p>
        <p>Přejeme vám příjemný výběr a šťastnou inspiraci!</p>
    
    
        <ListMenu storeName="hrany"/>
    </div>
</template>
<script>
import ListMenu from '@/views/products/components/listMenu.vue'

export default {
    components: {
        ListMenu
    }
}
</script>
