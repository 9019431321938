<template lang="html">
    <div>
        <h1>Kyvné dveře tzv. lítačky</h1>
        <p>
            Že vám ten název nic neříká? Určitě je znáte. Tento americký vynález proslavil již skoro před sto lety v jedné své grotesce nesmrtelný 
            Charlie Chaplin – na stříbrném plátně jako číšník vpadával do dveří v nečekanou chvíli a v nečekaném směru, 
            jejich křídly vyrážel svým kolegům z rukou plné talíře a kuchařům působil boule na hlavě.
        </p>

        <h2>Proč zvolit kyvné dveře?</h2>
        <p>
            Kyvné dveře jsou ve skutečnosti velmi praktické. Někdy se jim také říká „lítačky“ a jsou ideální variantou ve vysoce frekventovaných prostorách, 
            jako je například propojení kuchyně a jídelny nebo vchod do skladu. Umožňují totiž otevírání na obě strany, aniž byste používali 
            rukou – stačí do nich jen lehce vrazit bokem či nohou, a jejich křídla se rozevřou. Tyto výhody nejvíce oceníte zejména v kuchyni, 
            například ve chvíli, kdy ponesete v jedné ruce tác a v druhé láhev. Kyvné dveře mohou být dvoukřídlé i jednokřídlé.<br/><br/>
            Kyvné dveře jsou opatřeny speciálními závěsy, které umožňují otevírání křídla dveří libovolně oběma směry a následné samozavírání. 
            Pro zvýšení bezpečnosti je vhodné tyto dveře volit v prosklené variantě, aby se předešlo kolizím.
        </p>
    </div>
</template>