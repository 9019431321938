<template lang="html">
    <div>
        <h1>{{ name }}</h1>

        <div v-if="imgPath" class="img-preview">
            <img :src="require('@/assets/images/' + imgPath)" :alt="name"/>
        </div>
        <div v-else class="img-preview">
            <img :src="require('@/assets/images/' + storeName + '_' + id + '.jpg')" :alt="name"/>
        </div> 

        <div v-html="description"></div>
    </div>
</template>
<script>
export default {
    props: {
        id: {
            type: String,
            default: ''
        },
        name: {
            type: String,
            default: ''
        },
        description: {
            type: String,
            default: ''
        },
        imgPath: [String, Array],
        storeName: String
    }
}
</script>
<style lang="scss">
    
</style>