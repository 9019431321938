<template lang="">
    <div>
        <h1>Interiérové dveře - výběr modelových řad</h1>
        <ListMenu storeName="interiorDoors"/>
    </div>
</template>
<script>
import ListMenu from '@/views/products/components/listMenu.vue'

export default {
    components: {
        ListMenu
    }
}
</script>
<style lang="scss" scoped>
    @import "@/resources/styles/Base/Mixins/_responsive.scss";

    .img {
        max-width: 8rem;

        @include res-max-mobile {
            max-width: 100%;
        }
    }

    @include res-max-mobile {
        
        .door-list {
          justify-content: center;

          > * {
            flex: 0 0 30%;
          }
        }
    }
</style>