<template lang="html">
    <div v-if="imgPath" class="modal-overlay c-p" @click="$emit('close-modal')" >
        <div class="modal-overlay__box">
            <img :src="require('@/assets/images/' + imgPath)" class="modal-overlay__img"/>
            <div class="modal-overlay--close">
                <span></span>
                <span></span>    
            </div>
        </div>
    </div>
</template>
<script>
import { ref, computed } from 'vue';

export default {
    props: {
        imgPath: {
            type: String,
            default: null
        }
    }
}
</script>
<style lang="scss" scoped>
    @import "@/resources/styles/Base/Mixins/_responsive.scss";
    .modal-overlay {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.4);
        display: grid;
        place-items: center;
        z-index: 10000;
        user-select: none;
        
        &__box {
            position: relative;
            max-width: 100%;

            @include res-max-mobile {
                margin: var(--gap-lg)
            }
        }

        &__img {
            max-width: 100%;
        }

        &--close {
            position: absolute;
            bottom: 100%;
            right: 0;
            width: 30px;
            height: 30px;
            margin: var(--gap-md) 0;

            span {
                position: absolute;
                top: 50%;
                left: 0;
                display: block;
                width: 30px;
                height: 4px;
                background-color: white;
                border-radius: 2px;
                box-shadow: 0 0 4px 1px rgb(0 0 0 / 20%);

                &:first-child {
                    transform: rotate(-45deg);
                }
                
                &:last-child {
                    transform: rotate(45deg);
                }
            }
        }
    }
</style>