<template lang="html">
    <div>
        <h1>Zárubně interiérových dveří</h1>
        <p>
           Neoddělitelnou součástí každých <b>interiérových dveří jsou zárubně</b>, u kterých je důležitá vysoká kvalita. SOLODOOR nabízí široký výběr dveřních zárubní, ze kterých si vybere i zákazník s nejvyššími nároky na kvalitu i design.
        </p>
        <p>
           Nabízíme <b>klasické i bezfalcové obložkové</b> zárubně, <b>kovové zárubně pro požárně bezpečnostní dveře třídy 2 a 3 či speciální obložkové zárubně pro renovaci panelákových dveří</b>. Designovou lahůdkou jsou zárubně UNICO v unikátním povrchu SOLO MATRIX.
        </p>
        <p>
            <b>Nabízíme zárubně nejvyšší kvality, které vyrábíme v České republice</b>. Zárubně dveří vyrábíme v mnoha variantách povrchové úpravy, ať již v mnoha barevných provedení laku, fólie či vysoce odolných laminátových površích v mnoha dekorech dokonale imitující dřevo.
        </p>
        <p>
            Zárubeň SOLODOOR je zárukou dlouhodobé užitné hodnoty vašich interiérových dveří.
        </p>
        
        <ListMenu storeName="interZarub"/>
    </div>
</template>

<script>
import ListMenu from '@/views/products/components/listMenu.vue'

export default {
    components: {
        ListMenu
    }
}
</script>
