<template>
  <div class="h-100">
    <section class="section container h-100">
      <div class="section__aside h-100">
          <Aside />
        <div class="content" id="aside--content">
          <div v-if="isActiveMenu('products') && isActiveContent('none')">
              <h1>Dveře, zárubně a kování Solodoor</h1>

              <div class="d-f fd-r fw-w list-menu mb-xl">
                  <a class="c-p" @click.prevent="openMenu('interiorDoors')">
                    <img src="@/assets/images/menuInterior.jpg" alt="menu Interier"/>
                    <span class="mt-lg">Interiérové dveře</span>
                  </a>
                  <a class="c-p" @click.prevent="openMenu('interZarub')">
                    <img src="@/assets/images/menuInterZarub.jpg" alt="menu Zarubne"/>
                    <span class="mt-lg">Interiérové zárubně </span>
                  </a>
                  <a class="c-p" @click.prevent="openMenu('accessories')">
                    <img src="@/assets/images/menuAccesiories.jpg" alt="menu Prislusenstvi"/>
                    <span class="mt-lg">Příslušenství, mušle, kování </span>
                  </a>
                  <a class="c-p" @click.prevent="openMenu('posuvne')">
                    <img src="@/assets/images/menuPosuv.jpg" alt="menu Posuvné dveře"/>
                    <span class="mt-lg">Posuvné dveře</span>
                  </a>
                  <a class="c-p" @click.prevent="openMenu('hrany')">
                    <img src="@/assets/images/menuHrany.jpg" alt="menu Možnosti úpravy dveří"/>
                    <span class="mt-lg">Možnosti úpravy dveří</span>
                  </a>
                  <a class="c-p" @click.prevent="openMenu('povrchy')">
                    <img src="@/assets/images/menuPovrch.jpg" alt="menu Dekory dveří"/>
                    <span class="mt-lg">Dekory dveří</span>
                  </a>
                  <a class="c-p" @click.prevent="openMenu('proskleni')">
                    <img src="@/assets/images/menuProskleni.jpg" alt="menu Prosklení dveří"/>
                    <span class="mt-lg">Prosklení dveří</span>
                  </a>
              </div>

              <p>
                Značka SOLODOOR ve svých výrobcích kombinuje český řemeslný um s unikátními německými technologiemi. 
                Výsledkem jsou vysoce <strong>kvalitní interiérové dveře a zárubně</strong> se značkou Czech Made. 
                Součástí dodávky dveří SOLODOOR je <strong>profesionální poradenství, zaměření, montáž a řada dalších služeb.</strong>
              </p>
              <p>
                Prohlédněte si nabídku dveří SOLODOOR a vyberte si podle toho, jestli máte zájem o klasické jednokřídlé nebo dvoukřídlé otočné dveře, kyvné „lítačky“ nebo moderní posuvné dveře.
              </p>
              <p>
                 Většinu modelů dveří nabízíme v širokém spektru materiálů a povrchových úprav od fólií s potiskem až po široký výběr typů a dekorů povrchu z vysoce odolného laminátu, 
                 včetně speciálních 3D a SOLO MATRIX povrchů, které jsou mnohonásobně odolnější než dřevo, od kterého jsou k nerozeznání. 
                 V nabídce jsou i dveře s lakovaným povrchem a celoskleněné dveře z bezpečnostního skla.<br/><br/>
                 Máte možnost volby různých typů prosklení a rámečků, včetně zapuštěných. Na výběr máte dveře falcové i bezfalcové, 
                 s unikátní oblou hranou i dveře celoskleněné. U mnoha designových variant jsou v nabídce i bezpečnostní, protipožární, kouřotěsné, 
                 protihlukové dveře a tzv. klima dveře, které jsou odolné proti vlhkosti a výkyvům teplot. 
              </p>
              <p>
                Součástí nabídky jsou i kvalitní obložkové zárubně a různé druhy klik, kování a doplňků interiérových dveří.
              </p>
          </div>

          <div v-if="isActiveMenu('interiorDoors')">
             <ProdInterior v-if="isActiveContent('none')"/>
             <DetailInter 
                v-if="objInter != undefined"
                :id="objInter.id"
                :title="objInter.title"
                :description="objInter.description"
                :name="objInter.name"
                :list="objInter.list"
                />
          </div>

          <div v-if="isActiveMenu('accessories')">
              <ProdAccess/>
          </div>

          <div v-if="isActiveMenu('zatez')">
              <ProdZatez/>
          </div>
          
          <div v-if="isActiveMenu('kyvne')">
              <ProdKyvne/>
          </div>
          <div v-if="isActiveMenu('svetliky')">
              <ProdSvetlik/>
          </div>
          <div v-if="isActiveMenu('povrchy')">
              <ProdPovrch/>
          </div>
          <div v-if="isActiveMenu('proskleni')">
              <ProdProskleni/>
          </div>
          
          <div v-if="isActiveMenu('posuvne')">
              <ProdPosuv v-if="isActiveContent('none')"/>
              <Detail
                v-if="objPosuv != undefined"
                :id="objPosuv.id"
                :name="objPosuv.name"
                :description="objPosuv.description"
                
                storeName="posuvne"/>
          </div>

          <div v-if="isActiveMenu('interZarub')" class="interZarub">
              <ProdInterZarub v-if="isActiveContent('none')"/>
              <Detail
                v-if="objInterZarub != undefined"
                :id="objInterZarub.id"
                :name="objInterZarub.name" 
                :description="objInterZarub.description"
                :imgPath="objInterZarub.imgPath"
                storeName="interZarub"/>
          </div>

          
          <div v-if="isActiveMenu('hrany')">
              <ProdHrany v-if="isActiveContent('none')"/>
              <Detail
                v-if="objHrany != undefined"
                :id="objHrany.id"
                :name="objHrany.title" 
                :description="objHrany.description"
                :imgPath="objHrany.imgPath"
                storeName="hrany"/>
          </div>

          </div>
      </div>
    </section>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useStore } from "vuex"
import { useRouter, useRoute } from 'vue-router'

import ProdInterior from './productInterior.vue'
import ProdInterZarub from './productZarub.vue'
import ProdAccess from './productAccesories.vue'
import ProdZatez from './productZatez.vue'
import ProdKyvne from './productKyvne.vue'
import ProdPosuv from './productPosuv.vue'
import ProdSvetlik from './productSvetlik.vue'
import ProdHrany from './productHrany.vue'
import ProdPovrch from './productPovrch.vue'
import ProdProskleni from './productProskleni.vue'

import DetailInter from './details/detailInter.vue'
import Detail from './details/detail.vue'

import Aside from './components/aside.vue'

export default {
  name: 'Products',
  components: {
    ProdInterior,
    ProdInterZarub,
    ProdAccess,
    ProdZatez,
    ProdKyvne,
    ProdPosuv,
    ProdSvetlik,
    ProdHrany,
    ProdPovrch,
    ProdProskleni,
    Aside,
    DetailInter,
    Detail
  },
  setup() {
      const route = useRoute()
      const router = useRouter()
      const store = useStore()
      
      const activeMenuItem = ref(route.query.type)
      const activeContent = ref(route.query.product)

      if(!activeMenuItem.value) {
          router.push({query: {type: 'products', product: 'none'}})
      }

      const objInter = ref()
      const objInterZarub = ref()
      const objPosuv = ref()
      const objHrany = ref()

      function isActiveMenu(menuItem) {
        return activeMenuItem.value == menuItem
      }
      function setActiveMenuItem(menuItem) {
        activeMenuItem.value = menuItem
      }

      function isActiveContent(content) {
        return activeContent.value == content
      }
      function setActiveContent(content) {
        activeContent.value = content

        if (activeMenuItem.value == 'interiorDoors') {
          objInter.value = store.state.interiorDoors.find(z => z.id === content)
        }
        else if (activeMenuItem.value == 'interZarub') {
          objInterZarub.value = store.state.interiorZarub.find(z => z.id === content)
        }
        
        else if (activeMenuItem.value == 'posuvne') {
          objPosuv.value = store.state.posuvne.find(z => z.id === content)
        }
        
        else if (activeMenuItem.value == 'hrany') {
          objHrany.value = store.state.hrany.find(z => z.id === content)
        }
      }
      
      function openMenu(menuItem) {
          router.push({query: {type: menuItem, product: 'none'}})
      }
      
      setActiveContent(activeContent.value)

      return {
        activeMenuItem,
        activeContent,
        setActiveMenuItem,
        isActiveMenu,
        setActiveContent,
        isActiveContent,
        openMenu,
        objInter,
        objInterZarub,
        objPosuv,
        objHrany
      }
  },
  watch:{
    $route (to, from){
        this.setActiveMenuItem(to.query.type)
        this.setActiveContent(to.query.product)
    }
  } 
}
</script>

<style lang="scss">
  @import "@/resources/styles/Base/Mixins/_responsive.scss";

  p {
    text-align: justify;
  }

  .name {
        font-weight: 600;
        text-transform: uppercase;
        text-decoration: underline;
  }

  .interZarub__item {
    img {
      width: 30rem;
      max-width: 100%;
    }
  }

  .img-preview {
      margin-bottom: var(--gap-xl);

      img {
          max-width: 100%;
      }

      &--list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        

        > * {
          flex: 0 0 48%;
          margin-right: var(--gap-md);
        }
      }
  }

    .list-items {
        gap: var(--gap-md);
        text-align: center;

        > * {
            flex: 0 0 82px
        }

    }

    .list-items__preview {
        border: 1px solid #909090;
        padding: 1px;
        display: block; 
        width: 82px;
        height: 82px;
        margin-bottom: var(--gap-md);

        img {
            width: 100%;
        }
    }

</style>