<template lang="html">
    <div>
        <h1>Prosklení interiérových dveří</h1>
        <p>
            V případě, že si chcete domov prosvětlit a rozhodnete se pro prosklené dveře, můžete si vybrat z několika druhů kvalitních skleněných výplní. 
            V našem katalogu vám nabízíme široký sortiment skel v různých variantách. 
            Naleznete zde také několik unikátních designových skel v provedení od čirých až po ozdobná.
        </p>
        <p>
            Prosklení interiérových dveří, případně i použití dveřních světlíků, dokáže váš byt významně projasnit a odlehčit. 
            Při výběru skla se ovšem řiďte nejen designem, ale i funkcí a umístěním dveří. 
            Do dveří u dětského pokoje například raději objednejte osmimilimetrové kalené bezpečnostní sklo.
        </p>
        <p>
            Čiré sklo se hodí spíše k menším, ozdobným plochám na dveřích, které nevedou nikam do soukromí. 
            Pro velké prosklené plochy dveří volte raději bezpečnostní sklo se strukturovaným povrchem. 
            Světlo jím proniká v dostatečné míře a místnost si zachová svůj charakter, protože skrz dveře není vidět do místností vedlejších.
        </p>

        <div class="list-items d-f fw-w">
            <div v-for="color in list" :key="color.name" class="c-p"
                @click="selectedImg = 'proskleni/' + color.preview  + '-big.jpg'">
                <div class="list-items__preview">
                    <img :src="require('@/assets/images/proskleni/' + color.preview + '.jpg')" :alt="color.name"/>
                </div>
                <span>{{color.name}}</span>
            </div>
        </div>

        <h2>Skla pro modelovou řadu Sonet</h2>
        <div class="list-items d-f fw-w">
            <div v-for="color in listSonet" :key="color.name" class="c-p" 
                @click="selectedImg = 'proskleni/' + color.preview  + '-big.jpg'">
                <div class="list-items__preview">
                    <img :src="require('@/assets/images/proskleni/' + color.preview + '.jpg')" :alt="color.name"/>
                </div>
                <span>{{color.name}}</span>
            </div>
        </div>

        <Modal :imgPath="selectedImg" v-on:close-modal="selectedImg = ''"/>
    </div>

</template>
<script>
import { ref } from 'vue'
import Modal from './components/imgDetail.vue'

export default {
    components: {
        Modal
    },
    data() {
        return {
            selectedImg: ''
        }
    },
    setup() {
        const list = [
            { name: 'Činčila bronz', preview: 'cb', image: '' },
            { name: 'Činčila čirá', preview: 'cc', image: '' },
            { name: 'Čiré sklo', preview: 'cs', image: '' },
            { name: 'Kůra bronz', preview: 'kb', image: '' },
            { name: 'Kůra čirá', preview: 'kc', image: '' },
            { name: 'Master carre', preview: 'mc', image: '' },
            { name: 'Ornament činčila', preview: 'oc', image: '' },
            { name: 'Ornament grepi', preview: 'og', image: '' },
            { name: 'Planibel bronz', preview: 'pb', image: '' },
            { name: 'Planibel šedý', preview: 'ps', image: '' },
            { name: 'Planibel zelený', preview: 'pz', image: '' },
            { name: 'Satinato', preview: 's', image: '' }
        ]

        const listSonet = [
            { name: 'Float čírý', preview: 'fc', image: '' },
            { name: 'Float pískovaný vzor I', preview: 'fp1', image: '' },
            { name: 'Float pískovaný vzor II', preview: 'fp2', image: '' },
            { name: 'Float pískovaný vzor III', preview: 'fp3', image: '' },
            { name: 'Float pískovaný vzor IV', preview: 'fp4', image: '' },
            { name: 'Float pískovaný vzor V', preview: 'fp5', image: '' },
            { name: 'Float pískovaný vzor VI', preview: 'fp6', image: '' },
            { name: 'Float pískovaný vzor VII', preview: 'fp7', image: '' },
        ]

        return {
            list,
            listSonet
        }
    }
}
</script>
<style lang="scss" scoped>
    
</style>
