<template lang="html">
    <div>
        <h1>Posuvné interiérové dveře</h1>

        <div class="img-preview">
            <img src="@/assets/images/posuvne.jpg">
        </div>
        <p>Na rozdíl od klasických otočných nebo kyvných dveří nevyžadují posuvné dveře při otevírání žádný volný prostor v místnosti a jsou tak <b>ze všech variant otevírání dveří prostorově nejúspornější</b>.</p>

        <h2>Typy posuvných dveří</h2>
        <ListMenu storeName="posuvne" />
        <h2>Jaké jsou možnosti otevírání?</h2>
        <p>
            Posuvné dveře si můžete vybírat z variant, které jsou <b>jednokřídlé</b> nebo <b>dvoukřídlé, posouvají se od sebe na dvě strany nebo na jednu stranu</b> za sebe. 
            Posuvné dveře vám pomohou ušetřit spoustu místa a příjemně ozvláštní také design interiéru vašeho bytu. 
            Obzvláště oblíbené jsou posuvné dveře do koupelny, protože tam je úspora prostoru velmi důležitá.
        </p>
        <p>Posuvné dveře značky SOLODOOR se vyrábí v České republice, takže můžeme důkladně dohlížet na jejich kvalitu.</p>

        <h3>Výhody posuvných dveří</h3>
        <ul class="list">
            <li>Úspora prostoru</li>
            <li>Dveře jsou bezbariérové</li>
            <li>Jednoduché a bezhlučné otevírání</li>
            <li>Moderní design, který ozvláštní interiér</li>
        </ul>
        <p>Zaujaly vás posuvné dveře na stěnu? Kontaktujte svého nejbližšího prodejce Solodoor nebo si sestavte vlastní dveře v našem konfigurátoru dveří.</p>

        <div class="img-preview mt-xl">
            <img src="@/assets/images/posuvne_types.jpg">
        </div>
    </div>
</template>
<script>
import ListMenu from '@/views/products/components/listMenu.vue'

export default {
    components: {
        ListMenu
    }
}
</script>
<style lang="scss" scoped>
    
</style>