<template lang="html">
    <div class="aside" id="aside">
          <a class="title c-p" @click.prevent="setActive('products')">Produkty</a>
          <div class="aside__item--box">
            <a class="aside__item" @click.prevent="setActive('interiorDoors')" :class="{ active: isActive('interiorDoors') }" href="#">
                Interiérové dveře
            </a>
          </div>
          <div class="aside__item--box">
            <a class="aside__item" @click.prevent="setActive('interZarub')" :class="{ active: isActive('interZarub') }" href="#">
              Interiérové zárubně
            </a>
          </div>
          
          <div class="aside__item--box">
            <a class="aside__item" @click.prevent="setActive('accessories')" :class="{ active: isActive('accessories') }" href="#">
              Příslušenství, mušle, kování
            </a>
          </div>
          
          <div class="aside__item--box">
            <a class="aside__item" @click.prevent="setActive('posuvne')" :class="{ active: isActive('posuvne') }" href="#">
              Posuvné dveře
            </a>
          </div>
          
          <div class="aside__item--box">
            <a class="aside__item" @click.prevent="setActive('zatez')" :class="{ active: isActive('zatez') }" href="#">
              Zátěžové dveře
            </a>
          </div>
          <div class="aside__item--box">
            <a class="aside__item" @click.prevent="setActive('kyvne')" :class="{ active: isActive('kyvne') }" href="#">
              Kyvné dveře
            </a>
          </div>
          
          <div class="aside__item--box">
            <a class="aside__item" @click.prevent="setActive('svetliky')" :class="{ active: isActive('svetliky') }" href="#">
              Světlíky
            </a>
          </div>
          
          <div class="aside__item--box">
            <a class="aside__item" @click.prevent="setActive('povrchy')" :class="{ active: isActive('povrchy') }" href="#">
              Povrchy dveří
            </a>
          </div>
          
          <div class="aside__item--box">
            <a class="aside__item" @click.prevent="setActive('proskleni')" :class="{ active: isActive('proskleni') }" href="#">
              Prosklení dveří
            </a>
          </div>
          
          <div class="aside__item--box">
            <a class="aside__item" @click.prevent="setActive('hrany')" :class="{ active: isActive('hrany') }" href="#">
              Provedení hran dveří
            </a>
          </div>
        </div>
</template>

<script>
import { useRouter, useRoute } from 'vue-router'

export default {
    setup() {
        const router = useRouter()
        const route = useRoute()
        
        function setActive(menuItem) {
            router.push({query: {type: menuItem, product: 'none'}})
        }

        function isActive(menuItem) {
            return route.query.type === menuItem
        }
        
        return {
            setActive,
            isActive
        }
    }
}
</script>

<style lang="scss">
    
</style>