<template lang="html">
    <div>
        <h1>Zátěžové dveře interiérové</h1>

        <div class="img-preview">
            <img src="@/assets/images/zatezove-dvere.jpg">
        </div>

        <p>
            Zátěžové dveře se uplatní všude tam, kde je velký provoz, tedy zejména ve veřejných či administrativních budovách, 
            v nemocnicích a lékařských ordinacích nebo ve školách. Jde o typ interiérových dveří uzpůsobený velkému namáhání, 
            s vysokou pevností a odolností povrchu (např. vůči poškrábání). Hodí se ovšem i do dětského pokoje k malým dětem, 
            které ještě nemají úplně zažito, jak velká síla stačí na jaký úkon, a klidně se na dveře zavěsí vahou celého těla. 
            Stejně tak musí dveře další řadu let odolávat i jejich trochu divočejším hrám.
        </p>

        <p>
            Odolnost povrchu zajišťují laminátové povrchy - CPL, CPL SOLO 3D, SOLO MATRIX a LAMINÁT 0,8 mm. 
            Zátěžové dveře mohou být i prosklené, výplň by však mělo tvořit bezpečnostní sklo CONNEX. 
            Můžete si zvolit i zátěžové dveře požárně  bezpečností (bezpečnostní třídy 2).
        </p>
    </div>
</template>